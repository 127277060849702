import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {PageProps} from "gatsby";
import {TwoToneText} from "../components/TwoToneText";
import {Layout} from "../containers/Layout";
import {Media} from "../constants";
import {useTimer} from "use-timer";
import {Button} from "primereact/button";
import {getPlayStoreUrl, isBrowser} from "../utils";

export default function Download(props: PageProps) {
    const params = new URLSearchParams(props.location.search);
    const [autoRedirect, setAutoredirect] = useState(params.has('autoredirect'));

    const downloadUrl = getPlayStoreUrl();
    const downloadRef = useRef();


    const {time, start, pause, reset, status} = useTimer({
        timerType: 'DECREMENTAL',
        endTime: 0,
        initialTime: 3,
        onTimeOver: () => {
            if (autoRedirect && !!downloadUrl && isBrowser()) {
                window.location.href = downloadUrl;
            }
        }
    });

    useEffect(() => {
        if (autoRedirect) {
            if (status != "RUNNING") {
                start();
            }
        } else {
            reset();
        }
    }, [autoRedirect, status]);

    return <Layout {...props} meta={{title: 'Download'}}>
        <Container>
            <h2><TwoToneText w1={'Download the'} w2={'Beta App'}/></h2>
            <div className={'content'}>
                <h3>Our beta app is now available on Google Play</h3>

                {autoRedirect ? <div className={'redirect_message p-message p-message-info'}>
                    You will be redirected to the PlayStore in {time} seconds... <Button className={'cancel_redirect p-button-text'} title={"cancel"} onClick={() => setAutoredirect(false)}><i className="pi pi-ban"/> Cancel</Button>
                </div> : <></>}
                <div className={'download_container'}>
                    <a ref={downloadRef} className="download_button" href={downloadUrl}><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                </div>
                {/*<CTA to={'https://play.google.com/store/apps/details?id=com.unprn.flutter_beta'} className={'download_link'}>Download from Playstore</CTA>*/}
                {/*<a href={}><img className={"image_"}*/}
            </div>
        </Container>
    </Layout>;
}

function Image({src}: { src: string }) {
    return <div className={'image'}>
        <img src={src} className={'p-shadow-4'}/>
    </div>;
}

const Container = styled.div`
  min-height: 75vh;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (${Media.laptop}) {
    width: 100%;
    max-width: 100rem;
  }

  h2 {
    text-align: center;
  }

  h3 {
    text-align: center;
  }

  .auto_redirect {

  }

  .redirect_message {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
    i {
      margin-right: 0.5rem;
    }
  }

  .download_container {
    text-align: center;
  }

  .download_link {
    margin: 2rem;

    button {
      padding: 2rem 3rem;
    }
  }

  .download_button {
    display: block;

    img {
      width: 300px;
    }
  }


  .image {
    margin: 0 auto;

    img {
      max-width: 100%;
    }
  }

  .howto {
    width: 100%;
    @media (${Media.laptop}) {
      margin-top: 2rem;
      padding: 0 2rem;
    }


    .p-inline-message {
      margin: 1rem 0;
    }

    .accordion {
    }

    .image {

    }

  }
`;
